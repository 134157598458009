import React from 'react';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import Layout from '../layouts/main/Layout';


const BlogPost = ({ pageContext: { locale }, data }) => {
  const { markdownRemark: post } = data;
  return (
    <Layout locale={locale}>
      <div dangerouslySetInnerHTML={{ __html: post.html }} />
    </Layout>
  );
};

BlogPost.propTypes = {
  pageContext: PropTypes.shape({
    locale: PropTypes.string,
  }).isRequired,
};

export default BlogPost;

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        slug
      }
    }
  }
`;
